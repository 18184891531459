import React, { Component } from 'react';
import { helpCircleOutline as help, arrowForwardOutline as forward, closeOutline as close } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonFooter,
  IonTextarea,
  withIonLifeCycle,
} from '@ionic/react';
import ShopServiceService from '../services/shopservice';
import ProfileService from '../services/profile';

class Services extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();

    this.state = {
      shop_service_id: undefined,
      note: '',
      services: [],
      shopServiceModal: false,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async componendDidMount() {
    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id);
    }
  }

  async ionViewWillEnter() {
    this.setState({
      id: this.props.id,
      profile: undefined,
      note: '',
      services: [],
    });

    try {
      let {
        data: { data: services },
      } = await ShopServiceService.get();

      await this.setState({ services });
    } catch (error) {
      console.error(error);
    }

    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id * 1);
    }
  }

  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
  }

  async setService(shop_service_id) {
    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    await this.setState({
      shop_service_id,
      shopServiceModal: true,
    });
  }

  async requestService(e) {
    try {
      let shopservice = {
        shop_service_id: this.state.shop_service_id,
        shop_service_name: this.state.services.find((service) => service.id === this.state.shop_service_id).name,
        note: this.state.note,
      };

      await ShopServiceService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  render() {
    const { services, shop_service_id, profile } = this.state;
    const service = services.find((service) => service.id === shop_service_id);

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/home" />
            </IonButtons>

            <IonTitle>Prenota un Servizio</IonTitle>

            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <p className="ion-padding-top ion-padding-start ion-padding-end">
            Ecco l'elenco dei nostri servizi, <br />
            seleziona il servizio che ti interessa e invia una richiesta al Farmacista
          </p>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            {this.state.services.map((service, i) => {
              return (
                <IonItem key={i} onClick={() => this.setService(service.id)}>
                  <IonLabel>{service.name}</IonLabel>
                  <IonIcon slot="end" icon={forward} />
                </IonItem>
              );
            })}
          </IonList>

          {!!service && (
            <IonModal isOpen={this.state.shopServiceModal}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton onClick={() => this.setState({ shopServiceModal: false, shop_service_id: undefined })}>
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Prenota un Servizio</IonTitle>
                </IonToolbar>
              </IonHeader>

              <IonContent>
                <IonItem>
                  <p>{service.name}</p>
                </IonItem>
                <div className="ion-padding" dangerouslySetInnerHTML={{ __html: service.description }} />
              </IonContent>

              <IonFooter>
                <IonToolbar>
                  {service.sdg_services[0] ? (
                    <IonList>
                      <ion-list-header>
                        <IonLabel className="ion-text-uppercase" color="success">
                          <b>Prenota i servizi</b>
                        </IonLabel>
                      </ion-list-header>
                      {service.sdg_services.map((sdgService) => {
                        return (
                          <IonItem
                            key={sdgService.id}
                            lines="full"
                            detail={true}
                            onClick={() => {
                              this.setState({ shopServiceModal: false });
                              this.props.history.push(`/app/reservations/${sdgService.id}`);
                            }}
                          >
                            <IonLabel slot="start">{sdgService.name}</IonLabel>
                            {!!sdgService.show_price && <IonLabel slot="end">€ {sdgService.price}</IonLabel>}
                          </IonItem>
                        );
                      })}
                      <ion-list-header>
                        <IonLabel className="ion-text-uppercase" color="success">
                          <b>Informazioni</b>
                        </IonLabel>
                      </ion-list-header>
                      <IonItem
                        lines="full"
                        detail={true}
                        onClick={() => {
                          this.setState({ shopServiceModal: false });
                          this.props.history.push(`/app/serviceinfo/${service.id}`);
                        }}
                      >
                        <IonLabel slot="start">Richiedi informazioni servizio</IonLabel>
                      </IonItem>
                    </IonList>
                  ) : (
                    <>
                      {profile === undefined || profile === null ? (
                        <>
                          <IonItem>
                            <p>
                              Sarai ricontattato al numero di telefono {profile.phone_number} oppure alla email{' '}
                              {profile.email} che hai inserito nel &nbsp;
                              <IonButton
                                color="light"
                                size="small"
                                onClick={() => {
                                  this.setState({ shopServiceModal: false, shop_service_id: undefined });
                                  this.props.history.push(`/app/profile`);
                                }}
                              >
                                tuo profilo
                              </IonButton>
                            </p>
                            <IonLabel position="stacked">Note aggiuntive</IonLabel>
                            <IonTextarea
                              type="text"
                              name="note"
                              placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                              value={this.state.note}
                              onIonChange={this.textHandler}
                            />
                          </IonItem>
                          <IonButton
                            expand="block"
                            color="success"
                            onClick={this.requestService}
                            className="ion-margin-horizontal"
                          >
                            Richiedi informazioni
                          </IonButton>
                        </>
                      ) : (
                        <IonToolbar>
                          <IonButton
                            className="ion-margin-horizontal"
                            expand="block"
                            color="light"
                            onClick={() => {
                              this.setState({ shopServiceModal: false, shop_service_id: undefined });
                              this.props.history.push(`/auth/login?redirect=/app/services`);
                            }}
                          >
                            Accedi per richiedere informazioni
                          </IonButton>
                        </IonToolbar>
                      )}
                    </>
                  )}
                </IonToolbar>
              </IonFooter>
            </IonModal>
          )}
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(Services);
