import React, { Component } from 'react';
import { helpCircleOutline as help, arrowForwardOutline as forward, closeOutline as close } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonFooter,
  IonTextarea,
  withIonLifeCycle,
} from '@ionic/react';

import ShopServiceGroup from '../services/shopServiceGroup';
import ShopServiceService from '../services/shopservice';

class ServiceGroup extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.files = React.createRef();

    this.state = {
      shop_service_id: undefined,
      note: '',
      services: [],
      shopServiceModal: false,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async componentDidMount() {
    if (!!this.props.match.params.id) {
      await this.setService(this.props.match.params.id, false);
    }
  }

  async ionViewWillEnter() {
    this.setState({
      id: this.props.id,
      note: '',
      group: undefined,
      services: [],
    });

    try {
      let { data: services } = await ShopServiceGroup.get(this.props.match.params.id);
      await this.setState({ group: services });
      await this.setState({ services: services.shopService });
    } catch (error) {
      console.error(error);
    }
  }

  async textHandler(event) {
    await this.setState({ [event.target.name]: event.detail.value });
  }

  async setService(shop_service_id, isClicked) {
    if (isClicked) {
      await this.setState({
        shop_service_id,
        shopServiceModal: true,
      });
    }
  }

  async requestService(e) {
    try {
      let shopservice = {
        shop_service_id: this.state.shop_service_id,
        shop_service_name: this.state.services.find((service) => service.id === this.state.shop_service_id).name,
        note: this.state.note,
      };

      await ShopServiceService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  render() {
    const { services, shop_service_id } = this.state;

    const service = services.find((service) => service.id === shop_service_id);

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/app/cart" />
            </IonButtons>

            <IonTitle>{!!this.state.group && !!this.state.group.name ? this.state.group.name : 'Servizi'}</IonTitle>

            <IonButtons slot="end">
              <IonButton href="/app/help/Services">
                <IonIcon icon={help} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <p className="ion-padding-top ion-padding-start ion-padding-end">
            {!!this.state.group && !!this.state.group.text ? (
              <span dangerouslySetInnerHTML={{ __html: this.state.group.text }} />
            ) : (
              <span>
                Ecco l'elenco dei nostri servizi, <br />
                seleziona il servizio che ti interessa e invia una richiesta al Farmacista
              </span>
            )}
          </p>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            {this.state.services.map((service, i) => {
              return (
                <IonItem key={i} onClick={() => this.setService(service.id, true)}>
                  <IonLabel>{service.name}</IonLabel>
                  <IonIcon slot="end" icon={forward} />
                </IonItem>
              );
            })}
          </IonList>

          {!!service && (
            <IonModal isOpen={this.state.shopServiceModal}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton onClick={() => this.setState({ shopServiceModal: false, shop_service_id: undefined })}>
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Prenota un Servizio</IonTitle>
                </IonToolbar>
              </IonHeader>

              <IonContent>
                <IonItem>
                  <p>{service.name}</p>
                </IonItem>
                <div className="ion-padding" dangerouslySetInnerHTML={{ __html: service.description }} />
              </IonContent>

              <IonFooter>
                <IonToolbar>
                  <IonItem>
                    <p>Se hai richieste particolari, puoi inserirle qui sotto.</p>
                    <IonLabel position="stacked">Note aggiuntive</IonLabel>
                    <IonTextarea
                      type="text"
                      name="note"
                      placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                      value={this.state.note}
                      onIonChange={this.textHandler}
                    />
                  </IonItem>
                  <IonButton
                    expand="block"
                    color="success"
                    onClick={this.requestService}
                    className="ion-margin-horizontal"
                  >
                    Richiedi informazioni
                  </IonButton>
                </IonToolbar>
              </IonFooter>
            </IonModal>
          )}
        </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(ServiceGroup);
